<!--
 * @Description: 首页 停车缴费 车位查询代缴 payForSpacePark
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-06-15 16:04:38
 * @LastEditors: zhoucheng
-->
<template>
  <div class='body'>
    <van-nav-bar title="车位查询代缴"
                 left-text="返回"
                 left-arrow
                 @click-left="handleClickTopBack" />
    <van-image :src="require('@/assets/wxParForParking/payForSpacePark.png')"
               width="100%"
               height="200px"></van-image>

    <van-row class="main">
      <van-row class="title">请输入泊位号</van-row>

      <van-field class="input"
                 v-model="parkSpaceNumber"
                 placeholder="请输入泊位号" />

      <van-row class="button">
        <van-button color="#19A9FC"
                    @click="handleClickQuery">查 询</van-button>
      </van-row>
      <van-row class="down">
        <van-row>提示：请输入准确的泊位号，避免出现缴错车辆或无法正常进行车费代缴!</van-row>
      </van-row>
    </van-row>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {
      parkSpaceNumber: '' // 车位号
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {

  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    // 查询车位订单
    handleClickQuery () {
      const info = {
        parkSpaceNumber: this.parkSpaceNumber
      }
      this.$payForSpacePark.queryOrderOnlineByParkSpaceId(info).then(res => {
        if (res.resultEntity) {
          this.$router.push({ name: 'payForSpaceParkOnline', query: { billingRuleDefId: res.resultEntity.billingRuleDefId, plateNumber: res.resultEntity.plateNumber, numberPlateColorCode: res.resultEntity.numberPlateColorCode } })
        } else {
          this.$toast('暂无订单！')
        }
      })
    },
    // 顶部返回按钮
    handleClickTopBack () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang='less' scoped>
// $imgWith: 100%;
//@import url(); 引入公共css类
.body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  .main {
    height: 40%;
    width: 93.6%;
    background: #ffffff;
    border-radius: 5px;
    position: relative;
    top: -68px;
    left: 3.2%;

    .title {
      height: 64px;
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 64px;
      text-align: center;
    }
    /deep/.van-cell::after {
      border-bottom: none !important;
    }
    .input {
      /deep/.van-field__value {
        width: 93.2%;
        height: 34px;
        background: #f6f6f6;
        border-radius: 17px;
        margin-top: 5px;
        .van-field__control {
          height: 34px;
          line-height: 34px;
          width: 202px;
          font-size: 17px;
          font-family: PingFang SC;
          font-weight: 400;
          margin-left: 5%;
        }
      }
    }
    .button {
      height: 40px;
      width: 100%;
      .van-button--normal {
        display: block;
        height: 40px;
        width: 50%;
        margin: 30px auto;
        border-radius: 5px;
        font-size: 17px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #ffffff;
      }
    }
    .down {
      font-size: 13px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #999999;
      margin: 25px 3.4% 0 3.4%;
      .van-row {
        margin: 8px;
      }
    }
  }
}
</style>
